// Core
import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
// BootStrap
import { Container, Navbar, NavDropdown, Nav } from "react-bootstrap";
// Library
import {
  Button,
  Drawer,
  Divider,
  makeStyles,
  List,
  ListItem,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListSubheader,
} from "@material-ui/core";
// Media
import PhoneIcon from "@material-ui/icons/Phone";
import Logo from "../assets/images/logo/v-developers-logo.png";
import MenuIcon from "../assets/images/menu-icon-top.svg";
import KeyboardReturnIcon from "@material-ui/icons/KeyboardReturn";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import axios from "axios";
import { URL } from "../config";
import ltrim from "validator/lib/ltrim";
import rtrim from "validator/lib/rtrim";
import isEmail from "validator/lib/isEmail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import TextField from "@material-ui/core/TextField";
import { Alert, Col, Row } from "react-bootstrap";
import CloseIcon from "@material-ui/icons/Close";
import EmailIcon from "@material-ui/icons/Email";
import Modal from "react-bootstrap/Modal";

const useStyles = makeStyles((theme) => ({
  list: {
    width: 300,
  },
  fullList: {
    width: "auto",
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Header = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  let location = useLocation().pathname;
  const [urlHomepage, setUrlHomepage] = useState("");
  const [urlCompany, setUrlCompany] = useState("");
  const [urlServices, setUrlServices] = useState("");
  const [urlProjects, setUrlProjects] = useState("");
  const [urlContactUs, setUrlContactUs] = useState("");
  const to_email = "contact@vdevelopers.com.au";
  const cc = "arkayappsseo@gmail.com";
  //   const to_email = "amit@arkayapps.com";
  //   const cc = "amit@arkayapps.com";
  const title = "Website Brochure Downloaded";
  const subject = "Inquiry Form - V-Developers";
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const template = `<!DOCTYPE html>
  <html lang="en">
  
  <head>
      <meta charset="UTF-8">
      <meta http-equiv="X-UA-Compatible" content="IE=edge">
      <meta name="viewport" content="width=device-width, initial-scale=1.0">
      <title>V Developers Brochure </title>
      <style>
          body {
              line-height: 1.4;
              font-family: sans-serif;
              background-color: #f6f6f6;
          }
  
          p {
              margin: 0;
              margin-bottom: 15px;
              font-size: 14px;
              font-weight: normal;
              font-family: sans-serif;
          }
  
          hr {
              border: 0;
              border-bottom: 1px solid #b9b9b9;
              margin: 10px 0;
          }
  
          .logo {
              margin-top: 1rem;
              margin-bottom: 1rem;
              width:80px;
            
          }
  
          .text-center {
              text-align: center !important;
          }
  
          .content {
              Margin: 0 auto;
              display: block;
              max-width: 580px;
              padding: 0 10px 0 10px;
              box-sizing: border-box;
          }
  
          .innerwrap {
              box-sizing: border-box;
              padding: 20px 20px 10px 20px;
              background: #f2f2f2;
          }
  
          .card-header {
              background:#292661;
              color: #fff;
              text-align: center;
              padding: 20px;
              font-size: 16px;
          }
  
          .card-header p {
              color: #fff;
              text-align: center;
              font-size: 16px;
              margin-bottom: unset;
          }
  
          .card-body {
              background: #ffffff;
              border-radius: 3px;
              width: 100%;
              box-sizing: border-box;
              padding: 20px;
              border-bottom: 3px solid #292661;
          }
  
          .card-body p strong {
              color: #333;
          }
  
          .card-body p strong.heading {
              color:#2A2765;
          }
  
          .copyright {
              color: #999999;
              font-size: 12px;
              text-align: center;
              margin-top: 1rem;
              margin-bottom: 1rem;
          }
      </style>
  </head>
  
  <body>
      <div class="content">
          <div class="text-center">
              <img src="https://vdevelopers.com.au/logo192.png" alt="logo" class="logo">
          </div>
          <div class="card-header">
              <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
              <p>V Developers Brochure Downloaded</p>
          </div>
          <div class="card-body">
              <p><strong>Name : </strong>${name}</p>
              <hr>
              <p><strong class="heading">Contact Information :</strong></p>
              <div class="innerwrap">
                  <p><strong>Mobile No: </strong>${mobile}</p>
                  <p><strong>Email: </strong>${email}</p>
              </div>
          </div>
          <div class="copyright">
              <p>&copy; 2024 V-Developers</p>
          </div>
      </div>
  </body>
  
  </html>`;

  useEffect(() => {
    checkURL();
    // eslint-disable-next-line
  }, []);

  const handleClick = () => {
    setOpen(!open);
  };

  const checkURL = () => {
    if (location === "/") {
      setUrlHomepage("active");
    } else if (location === "/about-us") {
      setUrlCompany("active");
    } else if (location === "/services") {
      setUrlServices("active");
    } else if (location.includes("/our-projects")) {
      setUrlProjects("active");
    } else if (location === "/contact-us") {
      setUrlContactUs("active");
    }
  };

  const [state, setState] = useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const onButtonClick = () => {
    const a = document.createElement("a");
    a.href = "V-Developers Company Profile.pdf";
    a.download = "V-Developers Company Profile.pdf";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  const mailError = <p>{error}</p>;

  const handleName = (e) => {
    const value = e.target.value;
    setName(value);
    setError(null);
  };
  const handleEmail = (e) => {
    var value = e.target.value;
    value = ltrim(rtrim(value));
    setEmail(value);
    if (!isEmail(value)) {
      setError(<Alert variant={"danger"}>Please enter a valid email</Alert>);
      setDisabled(true);
    } else {
      setError(null);
      setDisabled(false);
    }
  };
  const handleMobile = (value) => {
    setMobile(value);
    setError(null);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (name === null) {
      setError(<Alert variant={"danger"}>Name Required</Alert>);
    } else if (email === null) {
      setError(<Alert variant={"danger"}>Email Required</Alert>);
    } else if (mobile === null) {
      setError(<Alert variant={"danger"}>Contact Number Required</Alert>);
    } else {
      setDisabled(true);
      const data = {
        title: title,
        to_email: to_email,
        template: template,
        cc: cc,
        subject: subject,
      };
      const headerConfig = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const url = "https://api.sendmail.adhyaynam.in/send-mail";
      axios
        .post(url, data, headerConfig)
        .then((response) => {
          document.getElementById("brochure-form").reset();
          setDisabled(false);
          onButtonClick();
          handleClose();
        })
        .catch((error) => {
          let message = "Something went wrong. Please try again later.";
          if (error.response) {
            console.log(error.response);
            message = error.response.message;
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error);
          }
          setError(<Alert variant={"danger"}>{message}</Alert>);
          setDisabled(false);
        });
    }
  };
  return (
    <div className="header sticky">
      <Container>
        <Navbar expand="lg">
          {["left"].map((anchor, index) => (
            <div key={index}>
              <img
                src={MenuIcon}
                alt="Menu"
                className="d-block d-sm-none menu-icon"
                onClick={toggleDrawer(anchor, true)}
              />
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
              >
                <div
                  className={clsx(classes.list, {
                    [classes.fullList]: anchor === "top" || anchor === "bottom",
                  })}
                  role="presentation"
                  onKeyDown={toggleDrawer(anchor, false)}
                >
                  <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"
                    subheader={
                      <ListSubheader
                        component="div"
                        className="text-center"
                        id="nested-list-subheader"
                      >
                        <img src={Logo} alt="Logo" />
                      </ListSubheader>
                    }
                    className={classes.root}
                  >
                    <Divider className="mt-4" />
                    <div>
                      <a href="/">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Home" />
                        </ListItem>
                      </a>
                      <a href="/about-us">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Company" />
                        </ListItem>
                      </a>
                      <a href="/services">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Services" />
                        </ListItem>
                      </a>
                      <ListItem button onClick={handleClick}>
                        <ListItemIcon>
                          <KeyboardReturnIcon className="fa-flip-horizontal" />
                        </ListItemIcon>
                        <ListItemText primary="Projects" />
                        {open ? <ExpandLess /> : <ExpandMore />}
                      </ListItem>
                      <Collapse in={open} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          <a href="/our-projects/development-projects">
                            <ListItem button className="ms-5">
                              <ListItemText primary="Development" />
                            </ListItem>
                          </a>
                          <a href="/our-projects/construction-projects">
                            <ListItem button className="ms-5">
                              <ListItemText primary="Construction" />
                            </ListItem>
                          </a>
                        </List>
                      </Collapse>
                      <a href="/contact-us">
                        <ListItem button>
                          <ListItemIcon>
                            <KeyboardReturnIcon className="fa-flip-horizontal" />
                          </ListItemIcon>
                          <ListItemText primary="Contact Us" />
                        </ListItem>
                      </a>
                    </div>
                  </List>
                </div>
              </Drawer>
            </div>
          ))}
          <Navbar.Brand href="/">
            <img src={Logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end"
          >
            <Nav className="align-items-baseline">
              <Nav.Link href="/" className={urlHomepage}>
                Home
              </Nav.Link>
              <Nav.Link href="/about-us" className={urlCompany}>
                Company
              </Nav.Link>
              <Nav.Link href="/services" className={urlServices}>
                Services
              </Nav.Link>
              <NavDropdown className={urlProjects} title="Projects">
                <NavDropdown.Item href="/our-projects/development-projects">
                  Development
                </NavDropdown.Item>
                <NavDropdown.Item href="/our-projects/construction-projects">
                  Construction
                </NavDropdown.Item>
              </NavDropdown>
              <Nav.Link
                href="/contact-us"
                className={urlContactUs}
                style={{ marginRight: "2em" }}
              >
                Contact Us
              </Nav.Link>
              {/* <Nav.Link onClick={onButtonClick} >Get Brochure</Nav.Link> */}
              <Button onClick={handleShow} className="btn-style-1">
                Company Profile
              </Button>
              <Nav.Link href="tel:+61 424 554 504" className="btn-style-1">
                <PhoneIcon /> +61 424 554 504
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </Container>
      <ScrollAnimation animateIn="fadeInUp">
        <Modal show={show} onHide={handleClose}>
          <Container>
            <Row>
              <Col style={{ padding: "0px" }}>
                <form
                  className="contact-form mt-0 rounded"
                  id="brochure-form"
                  onSubmit={handleFormSubmit}
                >
                  <div className="text-end">
                    <CloseIcon onClick={handleClose} />
                  </div>
                  <h3
                    style={{ fontSize: "30px", fontWeight: "bold" }}
                    className="mb-4"
                  >
                    Download Brochure
                  </h3>
                  <Row>
                    <Col xs={12}>
                      <TextField
                        className="form-control mt-unset"
                        label="Name"
                        size="small"
                        variant="outlined"
                        required
                        inputProps={{ minLength: 3, maxLength: 50 }}
                        onChange={handleName}
                      />
                    </Col>
                    <Col xs={12}>
                      <TextField
                        className="form-control"
                        label="Email"
                        size="small"
                        variant="outlined"
                        inputProps={{ minLength: 3, maxLength: 100 }}
                        onChange={handleEmail}
                      />
                    </Col>
                    <Col xs={12}>
                      <PhoneInput
                        country={"au"}
                        onChange={handleMobile}
                        inputProps={{
                          minLength: 3,
                          maxLength: 18,
                          required: true,
                        }}
                      />
                    </Col>
                    {mailError}
                    <Col md={12} className="text-center">
                      <input
                        className="btn-style-1 mt-3 fs-24"
                        type="submit"
                        disabled={disabled}
                        value={"Submit"}
                      />
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
          </Container>
        </Modal>
      </ScrollAnimation>
    </div>
  );
};

export default Header;
