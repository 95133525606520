import React from 'react'
import { Container, Col, Row } from 'react-bootstrap/';
import ScrollAnimation from 'react-animate-on-scroll';
import Commitment from '../assets/images/commitment.svg'
import CommitmentHover from '../assets/images/commitment-hover.svg'
import Values from '../assets/images/values.svg'
import ValuesHover from '../assets/images/values-hover.svg'
import Vision from '../assets/images/vision.svg'
import VisionHover from '../assets/images/vision-hover.svg'

const Mission = () => {
    return (
        <div>
            <section className="mission">
                <Container>
                    <ScrollAnimation animateIn="fadeInUp">
                        <div class="before">
                            <h3>Mission And Vision</h3>
                            <span>WHY US</span>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp">
                        <h2>What Makes Us The Best Choice !</h2>
                    </ScrollAnimation>
                    <Row>
                        <Col md={4} className="mission-row">
                            <div className="card-mission-1">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <table>
                                        <tr>
                                            <td>
                                                <div className="icon-box">
                                                    <img src={Commitment} alt="Commitment" className="or-1" />
                                                    <img src={CommitmentHover} alt="Commitment" className="hover-1" />
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <h3>Our Commitment</h3>
                                    <p>We put all our efforts and abilities with all the past Rich Experiences and commit to provide full satisfaction to our Customers.</p>
                                </ScrollAnimation>
                            </div>
                        </Col>
                        <Col md={4} className="mission-row">
                            <div className="card-mission-2">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <table>
                                        <tr>
                                            <td>
                                                <div className="icon-box">
                                                    <img src={Values} alt="Values" className="or-2" />
                                                    <img src={ValuesHover} alt="Values" className="hover-2" />
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <h3>Our Values</h3>
                                    <p>Integrity &amp; Honesty are the two main Pillars on which the Company has built its image of Trustworthiness.</p>
                                </ScrollAnimation>
                            </div>
                        </Col>
                        <Col md={4} className="mission-row">
                            <div className="card-mission-3">
                                <ScrollAnimation animateIn="fadeInUp">
                                    <table>
                                        <tr>
                                            <td>
                                                <div className="icon-box">
                                                    <img src={Vision} alt="Vision" className="or-3" />
                                                    <img src={VisionHover} alt="Vision" className="hover-3" />
                                                </div>
                                            </td>
                                        </tr>
                                    </table>
                                    <h3>Our Vision</h3>
                                    <p>We desire and do pledge, to always be the Topmost Company in eastern part of Melbourne - Australia and also to expand steadily.</p>
                                </ScrollAnimation>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </div>
    )
}

export default Mission
