import React from 'react'
import CountUp from 'react-countup';
import ScrollAnimation from 'react-animate-on-scroll';
import { Col, Row } from 'react-bootstrap/';
import Construction from '../assets/images/construction.png'
import Logo from '../assets/images/logo/logoSubheading.png';

const BuildingAndConstruction = () => {
    return (
        <div>
            <ScrollAnimation animateIn="fadeInUp">
                <img src={Construction} alt="Small Projects" className="img-fluid" />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <h2 className="heading-4">Building And Construction</h2>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <p>V Developers boasts itself on completion and delivery of possession of various unique / complex projects both residential as well as commercial.</p>
                <p>New start of every project is a New Challenge that we undertake.</p>
                <p>V Developers turns your DREAM-THOUGHTS into practical REALITY. Our Commitment starts from the day of finalisation of site/plot and ends on the day of delivery of possession with continued on & off line maintenance support at all times, just to maintain sweet & healthy relationship.</p>
                <p>V Developers offers complete guidance to its clients to overcome any sort of risk factors whatsoever and to minimise the cost as far as possible and to maintain and control the budget.</p>
                <p>V Developers before the start of the project, conducts thorough survey of the site including analysis of geotechnical reports, examine latent conditions and removal of hazardous materials including demolition if any etc, etc.</p>
                <p>When V Developers serves you as your construction partner, it provides you with stage-wise construction Experiences including Conceptual Design / Fund Management & Budget / Procurement of quality materials / Value engineering and Project Management.</p>
            </ScrollAnimation>
            <Row className="counts mt-5">
                <Col md={4} xs={6}>
                    <ScrollAnimation animateIn="fadeInUp">
                        <div className="number d-flex">
                            <CountUp end={32} duration={2.75} /><p className="suffix">+</p>
                        </div>
                        <h3>MINI Projects</h3>
                    </ScrollAnimation>
                </Col>
                <Col md={4} xs={6}>
                    <ScrollAnimation animateIn="fadeInUp">
                        <div className="number d-flex">
                            <CountUp end={13} duration={2.75} /><p className="suffix">+</p>
                        </div>
                        <h3>MEGA Projects</h3>
                    </ScrollAnimation>
                </Col>
                <Col md={4} xs={6}>
                    <ScrollAnimation animateIn="fadeInUp">
                        <div className="number d-flex">
                            <CountUp end={27} duration={2.75} /><p className="suffix">+</p>
                        </div>
                        <h3>Renovation &amp; Alteration</h3>
                    </ScrollAnimation>
                </Col>
            </Row>
        </div>
    )
}

export default BuildingAndConstruction
