// Core
import React from "react";
// BootStrap
import { Container, Col, Row } from "react-bootstrap/";
import AboutUs from "../assets/images/about-us.png";
import Build from "../assets/images/build.png";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import SliderIntro from "../components/SliderIntro";
import Mission from "../components/Mission";
import Testimonials from "../components/Testimonials";
import SliderProjectsCompleted from "../components/SliderProjectsCompleted";
import SliderProjectsOngoing from "../components/SliderProjectsOngoing";
// Library
import CountUp from "react-countup";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from "react-animate-on-scroll";
// Media
import PhoneIcon from "@material-ui/icons/Phone";
import MailIcon from "@material-ui/icons/Mail";
import RoomIcon from "@material-ui/icons/Room";
import Consultation from "../assets/images/consultation.png";
import Planning from "../assets/images/planning.png";
import Construction from "../assets/images/construction.png";
import Logo from "../assets/images/logo/logoSubheading.png";
import HouseRenovation from "../assets/images/house-renovation.png";

const Homepage = () => {
  return (
    <div>
      {/* <section id="intro">
        <Col md={6}>
          <div className="content"> */}
            <SliderIntro />
          {/* </div>
        </Col>
      </section> */}

      <section id="about" className="entry">
        <Container>
          <Row>
            <Col md={6}>
              <ScrollAnimation animateIn="fadeInUp">
                <img
                  src={AboutUs}
                  alt="About Us"
                  className="img-fluid d-none d-sm-block"
                />
              </ScrollAnimation>
            </Col>
            <Col md={6}>
              <ScrollAnimation animateIn="fadeInUp">
                <div class="before type-2">
                  <h3>
                    <img src={Logo} alt="logo" className="logo2" />
                  </h3>
                  <span>ABOUT US</span>
                </div>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <h2>Who We Are ?</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <img
                  src={AboutUs}
                  alt="About Us"
                  className="img-fluid d-block d-sm-none"
                />
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <p>
                  V Developers is a Melbourne - Australia based, Promoters &
                  Developers, Builders & Contractors(Civil Construction) Co.
                  with all the latest State-of-the-Art-Technology duly
                  specialised in Unique Architecture in residential as well as
                  commercial constructions.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <p>
                  V Developers has emerged as one of the leading landmark
                  property developers in the eastern suburban areas of Melbourne
                  - Australia, with completed projects worth $500 million or
                  more.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <a href="/about-us" className="btn-more">
                  Know More
                  <ArrowForwardIcon />
                </a>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section id="build">
                <Container>
                    <Row>
                        <Col md={6}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <div class="before">
                                    <h3>VConcrateBuild</h3>
                                    <span>Build</span>
                                </div>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h2>VConcrate Build</h2>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={Build} alt="About Us" className="img-fluid d-block d-sm-none" />
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p>Located in Melbourne, Australia, VConcrete Build specialises in constructing distinctive, well-structured, modern residences, apartments, and commercial buildings.</p>
                            </ScrollAnimation>
                            <ScrollAnimation animateIn="fadeInUp">
                                <p>Our primary objective is to provide the modern infrastructure that every Australian needs.</p>
                            </ScrollAnimation>
                        </Col>
                        <Col md={6}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <img src={Build} alt="About Us" className="img-fluid d-none d-sm-block" />
                            </ScrollAnimation>
                        </Col>
                    </Row>
                </Container>
            </section> */}
      {/* <section>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp">
                        <div class="before">
                            <h3>Our Projects</h3>
                            <span>PROJECTS</span>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp">
                        <h2>Projects We Have Completed</h2>
                    </ScrollAnimation>
                </Container>
                <Container fluid>
                    <SliderProjectsCompleted />
                </Container>
            </section> */}
      <Mission />
      <section>
        <Container>
          <ScrollAnimation animateIn="fadeInUp">
            <div class="before type-2">
              <h3>
                <img src={Logo} alt="logo" className="img-fluid" />
              </h3>
              <span>SERVICES</span>
            </div>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInUp">
            <h2>Services We Provide</h2>
          </ScrollAnimation>
          <Row>
            <Col md={3}>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="card-offer">
                  <img
                    src={HouseRenovation}
                    alt="Property Development"
                    className="img-fluid"
                  />
                  <div className="info">
                    <a href="/services">
                      <h3>Property Development</h3>
                    </a>
                    <p>
                      Maximum usage of space with minimum cost, designed with
                      luxurious look and keeping in mind all the aspects and
                      basic needs of our customers.
                    </p>
                    <a href="services" className="inner">
                      Know More
                      <ArrowForwardIcon />
                    </a>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={3}>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="card-offer">
                  <img
                    src={Consultation}
                    alt="Development Consultancy"
                    className="img-fluid"
                  />
                  <div className="info">
                    <a href="/services">
                      <h3>Development Consultancy</h3>
                    </a>
                    <p>
                      Our Expert Consultancy & Experienced Guidance turns your{" "}
                      Fantasy into Reality.
                    </p>
                    <a href="services" className="inner">
                      Know More
                      <ArrowForwardIcon />
                    </a>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={3}>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="card-offer">
                  <img
                    src={Planning}
                    alt="Project Management"
                    className="img-fluid"
                  />
                  <div className="info">
                    <a href="/services">
                      <h3>Project Management</h3>
                    </a>
                    <p>
                      Onsite day-to-day activities planned and managed on
                      priority basis in advance to overcome loss of time and
                      on-time completion of the project.
                    </p>
                    <a href="services" className="inner">
                      Know More
                      <ArrowForwardIcon />
                    </a>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
            <Col md={3}>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="card-offer">
                  <img
                    src={Construction}
                    alt="Building And Construction"
                    className="img-fluid"
                  />
                  <div className="info">
                    <a href="/services">
                      <h3>Building And Construction</h3>
                    </a>
                    <p>
                      Step-by-step process of development can be noticed from
                      time-to-time with all necessary Permissions and
                      Compliances duly procured from the concerned authorities.
                    </p>
                    <a href="services" className="inner">
                      Know More
                      <ArrowForwardIcon />
                    </a>
                  </div>
                </div>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container>
          <Row className="counts">
            <Col md={3} xs={6}>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="number d-flex">
                  <CountUp end={11} duration={2.75} />
                  <p className="suffix">+</p>
                </div>
                <h3>
                  Years of Rich Experience
                </h3>
              </ScrollAnimation>
            </Col>
            <Col md={3} xs={6}>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="number d-flex">
                  <p className="suffix">$</p>
                  <CountUp end={45} duration={2.75} />
                </div>
                <h3>Value of Net Asset</h3>
              </ScrollAnimation>
            </Col>
            <Col md={3} xs={6}>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="number d-flex">
                  <CountUp end={45} duration={2.75} />
                  <p className="suffix">+</p>
                </div>
                <h3>Project Completed</h3>
              </ScrollAnimation>
            </Col>
            <Col md={3} xs={6}>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="number d-flex">
                  <CountUp end={40} duration={2.75} />
                  <p className="suffix">+</p>
                </div>
                <h3>Satisfied Client</h3>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      {/* <section>
                <Container>
                    <ScrollAnimation animateIn="fadeInUp">
                        <div class="before">
                            <h3>Our Projects</h3>
                            <span>PROJECTS</span>
                        </div>
                    </ScrollAnimation>
                    <ScrollAnimation animateIn="fadeInUp">
                        <h2>Our Ongoing Projects</h2>
                    </ScrollAnimation>
                </Container>
                <SliderProjectsOngoing />
            </section> */}
      <section className="bg-design-1">
        <Testimonials />
        <Container className="cmt-1 cmb-1">
          <ScrollAnimation animateIn="fadeInUp">
            <div class="before">
              <h3>Contact Us</h3>
              <span>CONTACT</span>
            </div>
          </ScrollAnimation>
          <Row>
            <Col md={5}>
              <ScrollAnimation animateIn="fadeInUp">
                <h2 className="mb-unset">we are Just a click away</h2>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <p>
                  Please use our contact form or call us if you'd like more
                  information about our property development services in
                  Melbourne.
                </p>
              </ScrollAnimation>
            </Col>
            <Col md={7} className="text-center">
              <Row className="contact-info-2">
                <Col md={4}>
                  <ScrollAnimation animateIn="fadeInUp">
                    <h4>
                      <a href="tel:+61 424 554 504">
                        <div className="icon">
                          <PhoneIcon />
                        </div>
                        Call <br />
                        <span>+61 424 554 504</span>
                      </a>
                    </h4>
                  </ScrollAnimation>
                </Col>
                <Col md={4}>
                  <ScrollAnimation animateIn="fadeInUp">
                    <h4>
                      <a href="mailto:contact@vdevelopers.com.au">
                        <div className="icon">
                          <MailIcon />
                        </div>
                        Email <br />
                        <span>contact@vdevelopers.com.au</span>
                      </a>
                    </h4>
                  </ScrollAnimation>
                </Col>
                <Col md={4}>
                  <ScrollAnimation animateIn="fadeInUp">
                    <h4>
                      {/* eslint-disable-next-line */}
                      <a
                        href="https://www.google.com/maps/@-37.875369,145.281985,16z?hl=en"
                        target="_blank"
                      >
                        <div className="icon">
                          <RoomIcon />
                        </div>
                        Location <br />
                        <span>
                          69 Power Rd,
                          <br /> Bayswater VIC 3153, Australia.
                        </span>
                      </a>
                    </h4>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
};

export default Homepage;
