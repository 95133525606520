import React, { useState } from "react";
import axios from "axios";
import { URL } from "../config";
import ltrim from "validator/lib/ltrim";
import rtrim from "validator/lib/rtrim";
import isEmail from "validator/lib/isEmail";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { useHistory } from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import TextField from "@material-ui/core/TextField";
import { Alert, Container, Col, Row } from "react-bootstrap";
import RoomIcon from "@material-ui/icons/Room";
import EmailIcon from "@material-ui/icons/Email";
import PhoneIcon from "@material-ui/icons/Phone";

const ContactUs = () => {
  const to_email = "contact@vdevelopers.com.au";
  const cc = "arkayappsseo@gmail.com";
  const title = "Website Inquiry";
  const subject = "Inquiry Form - V-Developers";
  let history = useHistory();
  const [disabled, setDisabled] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [mobile, setMobile] = useState(null);
  const [message, setMessage] = useState(null);
  const template = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>V Developers</title>
    <style>
        body {
            line-height: 1.4;
            font-family: sans-serif;
            background-color: #f6f6f6;
        }

        p {
            margin: 0;
            margin-bottom: 15px;
            font-size: 14px;
            font-weight: normal;
            font-family: sans-serif;
        }

        hr {
            border: 0;
            border-bottom: 1px solid #b9b9b9;
            margin: 10px 0;
        }

        .logo {
            margin-top: 1rem;
            margin-bottom: 1rem;
            width:80px;
          
        }

        .text-center {
            text-align: center !important;
        }

        .content {
            Margin: 0 auto;
            display: block;
            max-width: 580px;
            padding: 0 10px 0 10px;
            box-sizing: border-box;
        }

        .innerwrap {
            box-sizing: border-box;
            padding: 20px 20px 10px 20px;
            background: #f2f2f2;
        }

        .card-header {
            background:#292661;
            color: #fff;
            text-align: center;
            padding: 20px;
            font-size: 16px;
        }

        .card-header p {
            color: #fff;
            text-align: center;
            font-size: 16px;
            margin-bottom: unset;
        }

        .card-body {
            background: #ffffff;
            border-radius: 3px;
            width: 100%;
            box-sizing: border-box;
            padding: 20px;
            border-bottom: 3px solid #292661;
        }

        .card-body p strong {
            color: #333;
        }

        .card-body p strong.heading {
            color:#2A2765;
        }

        .copyright {
            color: #999999;
            font-size: 12px;
            text-align: center;
            margin-top: 1rem;
            margin-bottom: 1rem;
        }
    </style>
</head>

<body>
    <div class="content">
        <div class="text-center">
            <img src="https://vdevelopers.com.au/logo192.png" alt="logo" class="logo">
        </div>
        <div class="card-header">
            <img src="https://arkayapps.s3.ap-south-1.amazonaws.com/assets/email.png">
            <p>You Received an Inquiry !</p>
        </div>
        <div class="card-body">
            <p><strong>Name : </strong>${name}</p>
            <p><strong>Message : </strong>${message}</p>
            <hr>
            <p><strong class="heading">Contact Information :</strong></p>
            <div class="innerwrap">
                <p><strong>Mobile No: </strong>${mobile}</p>
                <p><strong>Email: </strong>${email}</p>
            </div>
        </div>
        <div class="copyright">
            <p>&copy; 2023 V-Developers</p>
        </div>
    </div>
</body>

</html>`;

  const mailError = <p>{error}</p>;

  const handleName = (e) => {
    const value = e.target.value;
    setName(value);
    setError(null);
  };
  const handleEmail = (e) => {
    var value = e.target.value;
    value = ltrim(rtrim(value));
    setEmail(value);
    if (!isEmail(value)) {
      setError(<Alert variant={"danger"}>Please enter a valid email</Alert>);
      setDisabled(true);
    } else {
      setError(null);
      setDisabled(false);
    }
  };
  const handleMobile = (value) => {
    setMobile(value);
    setError(null);
  };
  const handleMessage = (e) => {
    const value = e.target.value;
    setMessage(value);
    setError(null);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (name === null) {
      setError(<Alert variant={"danger"}>Name Required</Alert>);
    } else if (mobile === null) {
      setError(<Alert variant={"danger"}>Contact Number Required</Alert>);
    } else if (message === null) {
      setError(<Alert variant={"danger"}>Message Required</Alert>);
    } else {
      setDisabled(true);
      const data = {
        title: title,
        to_email: to_email,
        template: template,
        cc: cc,
        subject: subject,
      };
      const headerConfig = {
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
      };
      const url = "https://api.sendmail.adhyaynam.in/send-mail";
      axios
        .post(url, data, headerConfig)
        .then((response) => {
          document.getElementById("contact-us").reset();
          setDisabled(false);
          history.push("/thank-you");
        })
        .catch((error) => {
          let message = "Something went wrong. Please try again later.";
          if (error.response) {
            console.log(error.response);
            message = error.response.message;
          } else if (error.request) {
            console.log(error.request);
          } else {
            console.log(error);
          }
          setError(<Alert variant={"danger"}>{message}</Alert>);
          setDisabled(false);
        });
    }
  };
  return (
    <div>
      <section id="breadcrumbs" class="breadcrumbs">
        <div class="container">
          <h1>Contact Us</h1>
          <ul>
            <li>
              <a href="./">Home</a>
            </li>
            <li>Contact Us</li>
          </ul>
        </div>
      </section>
      <section className="entry">
        <Container>
          <Row>
            <Col md={3} xs={12} className="contact-info my-auto">
              <ScrollAnimation animateIn="fadeInUp">
                <div className="icon">
                  <RoomIcon />
                </div>
                <h4>Address</h4>
                <p>
                  69 Power Rd,
                  <br /> Bayswater VIC 3153, Australia.
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="icon">
                  <EmailIcon />
                </div>
                <h4>Email</h4>
                <p>
                  <a href="mailto:contact@vdevelopers.com.au">
                    contact@vdevelopers.com.au
                  </a>
                </p>
              </ScrollAnimation>
              <ScrollAnimation animateIn="fadeInUp">
                <div className="icon">
                  <PhoneIcon />
                </div>
                <h4>Phone</h4>
                <p>
                  <a href="tel:+61 424 554 504">+61 424 554 504</a>
                </p>
              </ScrollAnimation>
            </Col>
            <Col md={9}>
              <ScrollAnimation animateIn="fadeInUp">
                <form
                  className="contact-form"
                  id="contact-us"
                  onSubmit={handleFormSubmit}
                >
                  <div class="before">
                    <h3>Get In Touch</h3>
                  </div>
                  <h2>We Are Always There to Help You</h2>
                  <Row>
                    <Col md={12}>
                      <TextField
                        className="form-control mt-unset"
                        label="Name"
                        size="small"
                        variant="outlined"
                        required
                        inputProps={{ minLength: 3, maxLength: 50 }}
                        onChange={handleName}
                      />
                    </Col>
                    <Col md={6}>
                      <TextField
                        className="form-control"
                        label="Email"
                        size="small"
                        variant="outlined"
                        inputProps={{ minLength: 3, maxLength: 100 }}
                        onChange={handleEmail}
                      />
                    </Col>
                    <Col md={6}>
                      <PhoneInput
                        country={"au"}
                        onChange={handleMobile}
                        inputProps={{
                          minLength: 3,
                          maxLength: 18,
                          required: true,
                        }}
                      />
                    </Col>
                    <Col md={12}>
                      <TextField
                        multiline
                        rows={4}
                        className="form-control"
                        size="small"
                        label="Message"
                        variant="outlined"
                        required
                        inputProps={{ minLength: 3, maxLength: 200 }}
                        onChange={handleMessage}
                      />
                    </Col>
                    {mailError}
                    <Col md={12} className="text-end">
                      <input
                        className="btn-style-1"
                        type="submit"
                        disabled={disabled}
                        value={"SEND MESSAGE"}
                      />
                    </Col>
                  </Row>
                </form>
              </ScrollAnimation>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="cmb-1">
        <ScrollAnimation animateIn="fadeInUp">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3149.3548801968036!2d145.28198671584627!3d-37.87538264534599!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad63c908b3a1113%3A0xd960e87e9a4f96da!2sV%20Developers%20Pty%20Ltd!5e0!3m2!1sen!2sin!4v1633081908396!5m2!1sen!2sin"
            width="100%"
            height="530"
            title="location"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </ScrollAnimation>
      </section>
    </div>
  );
};

export default ContactUs;
