import React from 'react'
import { Col, Container, Row } from 'react-bootstrap/';
import ScrollAnimation from 'react-animate-on-scroll';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Quote from '../assets/images/quote.svg'
import Logo from '../assets/images/logo/logoSubheading.png';

const Testimonials = () => {
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <Container className="testimonials">
                <ScrollAnimation animateIn="fadeInUp">
                    <div class="before-2 type-2">
                        {/* <h3><img src={Logo} alt='logo' className='img-fluid' /></h3> */}
                        <p className="stroke">Testimonials</p>
                    </div>
                </ScrollAnimation>
                <ScrollAnimation animateIn="fadeInUp">
                    <h2 className="text-center">Our Satisfied Customers' Feedback</h2>
                </ScrollAnimation>
                <Row>
                    <Col md={4} className="testi-row">
                        <div className="card-testimonials">
                            <img src={Quote} alt="Quote" className="img-fluid quote" />
                            <p>V Developers Is one of the best Civil Construction Co., I have come across, The teamwork is simply awesome & meticulous on the work site fulfilling all my required aspects within budget & time schedule.</p>
                            {/* <h4>Kevin Faminto</h4> */}
                        </div>
                    </Col>
                    <Col md={4} className="testi-row">
                        <div className="card-testimonials">
                            <img src={Quote} alt="Quote" className="img-fluid quote" />
                            <p>I was so much overwhelmed & elated to have Mr. Ramesh & his team members working for my newly constructed Dream House, I thank them all for thelr dedicated services & co-operation extended to satisfy me in all regards. I do hereby recommend V Developers to all those who are looking for a Trusted & Quality Construction Co.</p>
                            {/* <h4>Mona Hassan</h4> */}
                        </div>
                    </Col>
                    <Col md={4} className="testi-row">
                        <div className="card-testimonials">
                            <img src={Quote} alt="Quote" className="img-fluid quote" />
                            <p>It has been so much fun while having V Developers working for our project, with their true attitude & spirit, | am grateful & thankful for all their hard work and devotion.</p>
                            {/* <h4>Sirio</h4> */}
                        </div>
                    </Col>
                </Row>
                {/* <ScrollAnimation animateIn="fadeInUp">
                    <Slider {...settings}>
                        <div>
                            <div className="card-testimonials">
                                <img src={Quote} alt="Quote" className="img-fluid quote" />
                                <p>Vconcrete is one of the best construction companies I have worked with.</p>
                                <p>They were meticulous on site and have ensured that all my requirements were met while staying on schedule and budget.</p>
                            </div>
                        </div>
                        <div>
                            <div className="card-testimonials">
                                <img src={Quote} alt="Quote" className="img-fluid quote" />
                                <p>I was so elated to have Ramesh and his team helping me with the construction of my new house. It is exactly how I wanted it. I would recommend VConcrete to all those in search of an experienced and professional construction company.</p>
                            </div>
                        </div>
                        <div>
                            <div className="card-testimonials">
                                <img src={Quote} alt="Quote" className="img-fluid quote" />
                                <p>It has been really fun to work with VConcrete as they had the best attitude towards us, helping us out any time we needed them and adjusting the project details as we had desired. I am extremely thankful for VConcrete and its hard-working team.</p>
                            </div>
                        </div>
                    </Slider>
                </ScrollAnimation> */}
            </Container>
        </div>
    )
}

export default Testimonials
