// Core
import React, { useEffect, useState } from "react";
import { useLocation, Link } from "react-router-dom";
// BootStrap
import { Container, Col, Row } from "react-bootstrap";
import HomeIcon from "@material-ui/icons/Home";
import ApartmentIcon from "@material-ui/icons/Apartment";
import ContactMailIcon from "@material-ui/icons/ContactMail";
import RoomIcon from "@material-ui/icons/Room";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import ScheduleIcon from "@material-ui/icons/Schedule";
import LinkedInIcon from "@material-ui/icons/LinkedIn";

const Footer = () => {
  let url = useLocation().pathname;
  const [urlHomepage, setUrlHomepage] = useState("");
  const [urlProjects, setUrlProjects] = useState("");
  const [urlBloContact, setUrlContact] = useState("");
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    navigateTo(url);
    // eslint-disable-next-line
  }, []);

  const navigateTo = (url) => {
    if (url === "/") {
      setUrlHomepage("active");
      setUrlProjects("");
      setUrlContact("");
    } else if (url === "/our-projects") {
      setUrlHomepage("");
      setUrlProjects("active");
      setUrlContact("");
    } else if (url === "/contact-us") {
      setUrlHomepage("");
      setUrlProjects("");
      setUrlContact("active");
    }
    window.scrollTo(0, 0);
  };

  return (
    <div id="footer">
      <div className="content">
        <Container>
          <Row>
            <Col md={3}>
              <h4>V Developers</h4>
              <p>
                V Developers construction group is a Melbourne based company
                focused on delivering architecturally unique, highly specified
                residences, apartments, and commercial buildings.
              </p>
              <a href="https://au.linkedin.com/company/v-developers-pty-ltd" target="_blank" >
                <LinkedInIcon style={{ color: "white" }} />
              </a>
            </Col>
            <Col md={3}>
              <h5>Quick Links</h5>
              <ul>
                <li>
                  <h6>
                    <a href="/">Home</a>
                  </h6>
                </li>
                <li>
                  <h6>
                    <a href="/about-us">Company</a>
                  </h6>
                </li>
                <li>
                  <h6>
                    <a href="/services">Services</a>
                  </h6>
                </li>
                <li>
                  <h6>
                    <a href="/our-projects">Projects</a>
                  </h6>
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <h5>Important Links</h5>
              <ul>
                <li>
                  <h6>
                    <a href="/contact-us">Contact Us</a>
                  </h6>
                </li>
                <li>
                  <h6>
                    <a href="/sitemap">Sitemap</a>
                  </h6>
                </li>
                <li>
                  <h6>
                    <a href="/privacy-policy">Privacy Policy</a>
                  </h6>
                </li>
              </ul>
            </Col>
            <Col md={3}>
              <h5>Contact Us</h5>
              <div className="contact">
                <RoomIcon />
                <p>
                  69 Power Rd,
                  <br /> Bayswater VIC 3153, Australia.
                </p>
                <ScheduleIcon />
                <p>
                  <span style={{ fontWeight: 500 }}>Opening Hours :</span> 09 AM
                  to 5 PM
                </p>
                <PhoneIcon />
                <p>
                  {" "}
                  <a href="tel:+61 424 554 504">+61 424 554 504</a>
                </p>
                <EmailIcon />
                <p>
                  {" "}
                  <a href="mailto:contact@vdevelopers.com.au">
                    contact@vdevelopers.com.au
                  </a>
                </p>
              </div>
            </Col>
          </Row>
          <Row className="justify-content-md-center">
            <Col md={6}>
              <hr />
            </Col>
          </Row>
          <div className="copyright">
            {/* eslint-disable-next-line */}
            <p className="copyright">
              &copy; {currentYear} V Developers. All rights reserved. Website
              designed and developed by Arkay Apps.
            </p>
          </div>
        </Container>
        <div className="d-block d-sm-none menu-footer">
          <Row className="p-3">
            <Col className="b-right">
              <Link
                to="/"
                className={urlHomepage}
                onClick={() => navigateTo("/")}
              >
                <HomeIcon />
                <br />
                Home
              </Link>
            </Col>
            <Col className="b-right">
              <Link
                to="/our-projects"
                className={urlProjects}
                onClick={() => navigateTo("/our-projects")}
              >
                <ApartmentIcon />
                <br />
                Projects
              </Link>
            </Col>
            <Col>
              <Link
                to="/contact-us"
                className={urlBloContact}
                onClick={() => navigateTo("/contact-us")}
              >
                <ContactMailIcon />
                <br />
                Contact Us
              </Link>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Footer;
