import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Col } from "react-bootstrap";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Slider1 from "../assets/images/slider-1.webp";
import Slider2 from "../assets/images/slider-2.webp";
import Slider3 from "../assets/images/slider-3.webp";
import Slider4 from "../assets/images/slider-4.webp";

const bgImages = [Slider1, Slider2, Slider3, Slider4];

const IntroSlider = () => {
  const [bgImage, setBgImage] = useState(bgImages[0]);

  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    beforeChange: (current, next) => setBgImage(bgImages[next]), // Change background image on slide change
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <section
      id="intro"
      style={{
        background: `linear-gradient(111deg, #c1bdea 0%, #3A282821 100%, #39050500 100%), url(${bgImage})`,
        backgroundSize: "cover",
        padding: "130px 0",
      }}
    >
      <Col md={6}>
        <div className="content">
          <Slider {...settings}>
            <div>
              <h1>You Dream it, We Build it.</h1>
              <a href="/about-us">
                <p>
                  Learn more About Us <ArrowForwardIcon />
                </p>
              </a>
            </div>
            <div>
              <h2>We just don't Construct,</h2>
              <h2>We do develop your Future.</h2>
              <a href="/about-us">
                <p>
                  Learn more About Us <ArrowForwardIcon />
                </p>
              </a>
            </div>
            <div>
              <h2>Most Dependable Service Provider</h2>
              <h2>At your doorstep.</h2>
              <a href="/about-us">
                <p>
                  Learn more About Us <ArrowForwardIcon />
                </p>
              </a>
            </div>
            <div>
              <h2>Building A Better Future.</h2>
              <a href="/about-us">
                <p>
                  Learn more About Us <ArrowForwardIcon />
                </p>
              </a>
            </div>
          </Slider>
        </div>
      </Col>
    </section>
  );
};

export default IntroSlider;
