import React from 'react'
import ScrollAnimation from 'react-animate-on-scroll';
import Logo from '../assets/images/logo/logoSubheading.png';
import HouseRenovationImg from '../assets/images/house-renovation.png'

const PropertyDevelopment = () => {
    return (
        <div>
            <ScrollAnimation animateIn="fadeInUp">
                <img src={HouseRenovationImg} alt="Consultation" className="img-fluid" />
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <h4 className="heading-4">Property Development</h4>
            </ScrollAnimation>
            <ScrollAnimation animateIn="fadeInUp">
                <p>V Developers have earned 15 years of RICH experience in the field of Civil Construction and have always adopted the new & latest technology available / suitable for the project in particular.</p>
                <p>V Developers have always tried to render their best of services, at all times, to its valued clients, for which they are still remembered and the GOOD word-of-mouth of the clients are acting as a never ending advertisement.</p>
            </ScrollAnimation>
        </div>
    )
}

export default PropertyDevelopment
