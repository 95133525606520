import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ScrollAnimation from 'react-animate-on-scroll';
import Project1 from '../assets/images/projects/project-1.jpg'
import Project2 from '../assets/images/projects/project-2.jpg'
import Project3 from '../assets/images/projects/project-3.jpg'
import Project4 from '../assets/images/projects/project-4.jpg'

const SliderProjectsOngoing = () => {
    var settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 3,
        autoplay: true,
        autoplaySpeed: 2000,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <div>
            <ScrollAnimation animateIn="fadeInUp">
                <Slider {...settings}>
                    <div className="ongoing">
                        <img src={Project1} alt="Project" className="img-fluid" />
                        <div class="middle">
                            <div class="text">Rowville 3178</div>
                        </div>
                    </div>
                    <div className="ongoing">
                        <img src={Project2} alt="Project" className="img-fluid" />
                        <div class="middle">
                            <div class="text">Wantirna</div>
                        </div>
                    </div>
                    <div className="ongoing">
                        <img src={Project3} alt="Project" className="img-fluid" />
                        <div class="middle">
                            <div class="text">Rowville 3178</div>
                        </div>
                    </div>
                    <div className="ongoing">
                        <img src={Project4} alt="Project" className="img-fluid" />
                        <div class="middle">
                            <div class="text">Wantirna</div>
                        </div>
                    </div>
                    <div className="ongoing">
                        <img src={Project2} alt="Project" className="img-fluid" />
                        <div class="middle">
                            <div class="text">Rowville 3178</div>
                        </div>
                    </div>
                    <div className="ongoing">
                        <img src={Project3} alt="Project" className="img-fluid" />
                        <div class="middle">
                            <div class="text">Wantirna</div>
                        </div>
                    </div>
                    <div className="ongoing">
                        <img src={Project4} alt="Project" className="img-fluid" />
                        <div class="middle">
                            <div class="text">Rowville 3178</div>
                        </div>
                    </div>
                </Slider>
            </ScrollAnimation>
        </div>
    )
}

export default SliderProjectsOngoing
